<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Name <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.name"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6">
                      <v-btn color="success" @click="addTask">
                        <v-icon>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">Tasks <span class="red--text">*</span></p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="form.items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ index }">
                          {{ index + 1 }}
                        </template>
                        <template v-slot:item.action="{ index }">
                          <v-btn color="error" @click.stop="deleteTask(index)">
                            <v-icon>
                              mdi-delete
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-task
      :item="item"
      :listCatalog="listCatalog"
      :dialog="dialog"
      @close="close"
      @save="saveTask"
      type="other"
    ></dialog-task>
  </v-form>
</template>

<script>
import DialogTask from "@/components/DialogTask";

export default {
  name: "form-task-template",
  components: {
    DialogTask,
  },
  props: {
    form: Object,
    listCatalog: Array,
  },
  data: () => ({
    dialog: false,
    item: {},
    valid: true,
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Description",
        value: "description",
        sortable: false,
        divider: true,
      },
      {
        text: "Catalog",
        value: "subProcessId",
        sortable: false,
        divider: true,
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        divider: true,
      },
    ],
  }),

  methods: {
    async update() {
      if (this.$refs.form.validate() && this.form.items.length > 0) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("taskTemplate/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "All is required",
        });
      }
    },
    deleteTask(index) {
      if (index > -1) {
        this.form.items.splice(index, 1);
      }
    },
    addTask() {
      this.item = {
        description: "",
        subProcessId: null,
      };
      this.dialog = true;
    },
    saveTask(item) {
      const index = this.form.items.findIndex(x => x === item);
      if (index > -1) {
        this.form.items[index] = item;
      } else {
        this.form.items.push(item);
      }
    },
    close() {
      this.dialog = false;
    },
    onClickRow(item) {
      this.item = item;
      this.dialog = true;
    },
  },
};
</script>

<style></style>
